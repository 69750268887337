import * as React from 'react';
import PropTypes from 'prop-types';
import MonacoEditor, { useMonaco } from '@monaco-editor/react';

import { Ultilities } from '@ezyvet/ezydata-common';

// Hooks
import useWindowMessage from '../hooks/useWindowMessage';

/**
 * Attempt to set the id via url
 * I.E. https://code.ezyvet.com/index?id=test
 *
 * @returns {string}
 */
function getUniqueEditorId() {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get('id');
}

/**
 * @param {object} event
 * @param {string} id
 *
 * @returns {boolean}
 */
function messageFilter(event, id) {
    return typeof event === 'object'
        && event.code_editor_id === id;
}

/**
 * @param {object} [props={}]
 * @param {*} [props.style]
 * @param {EditorComponent} [target={}]
 *
 * @returns Editor component trait
 */
export function EditorComponentTrait(props = {}, target = {}) {
    const {
        style,
    } = props;

    const [state, setState] = React.useState({
        code_editor_id: getUniqueEditorId(),
        editable: true,
        language: 'json',
        value: '',
    });

    const messageData = useWindowMessage((event) => messageFilter(event, state.code_editor_id));
    const monaco = useMonaco();

    const EditorComponent = {
        /**
         * DO NOT USE DIRECTLY
         */
        $editor: null,

        /**
         * Use JSON values of the objects passed in so that
         * the effect is not triggered multiple times
         *
         * @param {React.EffectCallback} effect
         * @param {React.DependencyList} dependencies
         */
        useJSONEffect(effect, dependencies = []) {
            React.useEffect(effect, dependencies.map((dependency) => Ultilities.asJSON(dependency)));
        },

        /**
         * @param {object} newState
         *
         * @returns {void}
         */
        setState(newState) {
            setState((currentState) => ({
                ...currentState,
                ...newState,
            }));
        },

        /**
         * @param {object} value
         *
         * @returns {void}
         */
        onValueChange(value) {
            target.setState({
                value,
            });
        },

        /**
         * @return {void}
         */
        onEditorUpdate() {
            monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
                validate: true,
            });
        },

        /**
         * @return {void}
         */
        onMessageUpdate() {
            // Set our new editor state and let the parent know our new state
            if (
                messageData
                && typeof messageData === 'object'
            ) {
                target.setState({
                    ...messageData,
                    value: messageData.value || '',
                });
            } else {
                target.setState({
                    initial_value: '',
                });
            }
        },

        /**
         * @return {void}
         */
        onEditorStateChange() {
            // Send our editor state update to the parent
            window.parent.postMessage(state, '*');
        },

        /**
         * @returns Editor component
         */
        render() {
            target.useJSONEffect(target.onMessageUpdate, [messageData]);
            target.useJSONEffect(target.onEditorUpdate, [monaco]);
            target.useJSONEffect(target.onEditorStateChange, [state]);

            return (
                <MonacoEditor language={state.language}
                    value={state.value}
                    options={{
                        readOnly: !state.editable,
                        minimap: {
                            enabled: false,
                        },
                    }}
                    onChange={target.onValueChange}
                    onMount={target.onMount}
                    style={[style]}
                />
            );
        },
    };

    return Object.assign(target, EditorComponent);
}

export default function Editor(props) {
    return EditorComponentTrait(props).render();
}

Editor.propTypes = {
    style: PropTypes.any,
};
