import * as React from 'react';

/**
 * @param {(event: *) => boolean} [filter]
 *
 * @returns {*}
 */
export default function useWindowMessage(filter) {
    const [value, setValue] = React.useState(null);

    React.useLayoutEffect(() => {
        // Handler to call on message received
        function onMessage(event) {
            if (!filter || filter(event.data)) {
                setValue(event.data);
            }
        }

        // Add event listener
        window.addEventListener('message', onMessage);

        // Remove event listener on cleanup
        return () => window.removeEventListener('message', onMessage);
    }, []); // Empty array ensures that effect is only run on mount

    return value;
}
